.buy-form-container {
  background-image: url('/public/images/golden.jpg');
  padding: 80px 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  /* justify-content: space-between; */
  position: relative;
}

.image-container {
  width: 520px;
  position: absolute;
  bottom: 0;
  left: 9%;
}

.image-container img {
  width: 100%;
}

.buy-form-container-2 {
  /* max-width: 1280px; */
  display: flex;
  justify-content: end;

}

.buy-form__heading {
  font-size: 30px;
  font-weight: 500;
  line-height: 34.5px;
  color: white;
  padding-right: 40px;
  /* margin-left: auto; */
}

.our-partners {
  padding: 4rem;
  display: flex;
  justify-content: center;
}

.our-partners__heading {
  color: #301250;
  font-family: 'Helvetica';
  font-weight: 800;
  font-size: 50px;
  /* text-align: center; */
  padding-top: 1rem;
}

.logos-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  /* height: 70px; */
  max-width: 25%;
  display: flex;
  /* align-content: center; */
  align-items: center;
}

.price-input {
  outline: none;
  border: none;
  background-color: #f5f5f5;
  padding: 12px 0 12px 3px;
}

@media (width: 1024px) {
  .buy-form-container {

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 22px 0px;
  position: relative;
  }
}
@media (max-width: 1020px) and (min-width: 769px) {
  .buy-form-container {
    background-image: linear-gradient(to bottom right,  #fef5bf, #ffe7bd,#dbb782,#b88c54);
  }
}

@media (max-width: 768px) {
  .buy-form-container {
    background-image: linear-gradient(to bottom right, #fef5bf, #ffe7bd,#dbb782,#b88c54);
    
  }
}
@media (max-width: 480px) {
  .buy-form-container {
  
    padding: 15px 25px;
  }

  .buy-form__heading {
    font-size: 25px;
  }
}

.download-wrapper {
  margin-top: 27px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.download-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  margin-right: 9px;
  margin-bottom: 10px;
}
.download-link{
  margin-right: 5px;
  width: 107px;
  /* height: 32px; */
}


@media (max-width: 480px) {
  .buy-form-container {
    padding: 15px 25px;
  }

  .buy-form__heading {
    font-size: 22px;
  }
}

@media screen and (max-width: 640px) {
  .buy-form-container {
    justify-content: center;
  }

  .buy-form__heading {
    font-size: 28px;
    padding-right: 0px;
    /* text-align: center; */
    margin-right: 0px;
    margin-left: 0px;
  }

  .buy-form-container-2 {
    justify-content: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 991px) {
  .buy-form__heading {
    padding-right: 50px;
  }
}


@media screen and (max-width:1500px) {
  .image-container {
    width: 520px;
    position: absolute;
    bottom: 0;
    left: 5%;
  }
}
@media screen and (max-width:1366px) {
  .image-container {
    width: 520px;
    position: absolute;
    bottom: 0;
    left: 2%;
  }
}

@media screen and (max-width:1280px) {
  .image-container {
    width: 470px;
    position: absolute;
    bottom: 0;
    left: 0%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .image-container {
    width: 440px;
    position: absolute;
    bottom: 0;
    left: 0%;
  }
}

@media screen and (max-width:992px) {
  .buy-form-container {
    padding: 80px 30px;
  }

  .buy-form-container-2 {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
  }

  .image-container {
    display: none;
  }
}

@media screen and (max-width:767px) {
  .buy-form-container-2 {
    align-items: center;
  }

}

@media screen and (max-width:375px) {

  .buy-form__heading {
    font-size: 22px;
    line-height: 27px;
  }
}