.hero-banner {
  background-color: #CEA538;
  /* background-color: #E3DDCF; */
  /* padding: 22px 26px 0px 26px; */
  border-radius: 10px;
  max-width: 420px;
  width: fit-content;
  /* max-width: 461px; */
}

.live-price__container {
  padding: 22px 26px 0px 26px;
}

.metal-box {
  padding: 0px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  /* transition: all 0.1s ease-in-out; */
}
.metal-box {
  color: white;
  cursor: pointer;
}

.live-price {
  padding: 30px 0 15px 0;
  font-weight: 400;
  font-size: 48px;
  line-height: 55px;
  min-height: 100px;
  color: #ffffff;
}

.live-dot__indicator {
  background: #f30058;
  width: 9.77px;
  height: 9.77px;
  border-radius: 100%;
  animation: animate 1.5s linear infinite;
}

.live-rate__text {
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;

  color: #ffffff;
}

.live-rate__indicator {
  gap: 8px;
}

.gst-text {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  color: rgba(255, 255, 255, 0.75);
  padding-top: 10px;
}

.buy-form {
  padding-top: 15px;
}

.buy-form__tab {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  color: #ffffff;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.buy-now-form {
  padding: 25px 35px;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
}

.input-label {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;

  color: #0d0709;
}

.buy-form-input {
  outline: none;
  border: none;
  background-color: "white ! important";
  width: 178px;
  /* padding: 10px; */
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.price-input__container {
  background-color: #f5f5f5;
  border-radius: 10px;

  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.price-input-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding-left: 10px;
}

.sug-price__container {
  padding-top: 15px;
  /* gap: 30px; */
  cursor: pointer;
}

.sug-price {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  color: #0d0709;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 6px 12px;
  box-sizing: border-box;
}

.buy-now-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  background-color: #30154f;
  padding: 15px 50px;
  border-radius: 20px;
  margin: 0 auto;
  width: fit-content;
  color: #ffffff;
  cursor: pointer;
  margin-top: 35px;
}

.buy-form-input::-webkit-outer-spin-button,
.buy-form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 676px) {
  .metal-box {
    padding: 0px 10px;
  }
  .price-input__container {
    padding-left: 10px;
    padding-right: 10px;
  }

}
@media screen and (max-width:480px){
  .buy-now-form{
    padding: 25px 5px;
  }
  .live-price__container {
    padding: 22px 10px 0px 10px;
  }
}
