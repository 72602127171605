/* styles.css */
.chart-table-responsive {
    margin-top: 50px;
    /* overflow-x: auto; */
    display: flex;
    justify-content: center;
  }
  
  .custom-table-chart {
    max-width: 80%;
    margin: 0 auto; /* Center align the table */
    border-radius: 10px ;
  }
  
  .custom-table-chart tbody tr:hover {
    background-color: white !important;
  }
  
  .light-gray-background {
    background-color: lightgray;
  }
  
  .white-background {
    background-color: rgb(255, 255, 255);
  }
  
  .small-width {
    width: 30%;
  }
  
  .bigger-width {
    width: 70%;
  }
  
   /* .rounded-square {
    border-radius: 0 !important ;
    padding: 5px 10px; 
    font-size: 8px; 
  }  */

  .rounded-square {
    border-radius: 0 !important; /* Adjust the radius as needed for small rounded corners */
    padding: 5px 10px; /* Adjust padding for button size */
    font-size: 8px; /* Adjust font size for small text */
    transition: box-shadow 0.3s ease-in-out !important; /* Add a smooth transition effect */
  
    /* Set the initial box shadow to none */
    box-shadow: none !important;
  }
  
  /* Add the hover effect */
  .rounded-square:hover {
    box-shadow: 0 0 10px rgb(221, 221, 221) !important; /* White color shadow with some transparency */
  }
  

  /* /* styles.css */
.custom-table tbody tr:hover,
.custom-table tbody tr:hover td {
  background-color: transparent !important;
} 


/* .custom-table tbody tr:hover td {
    background-color: transparent !important;
  } */
  
  /* Media queries for responsiveness */
  @media (max-width: 767px) {
    .small-width,
    .bigger-width {
      width: 100%;
    }
  }
  